var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-content-box"},[_c('div',{staticClass:"info-banner",style:({
      backgroundImage: 'url(' + _vm.bannerImg + ')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'cover'
    })},[_c('head-main',{staticClass:"no-fixed"})],1),_c('div',{staticClass:"infomation-box"},[(_vm.swipeList.length > 0)?_c('div',{staticClass:"info-new-box"},[_c('div',{staticClass:"info-new-banner"},[_c('el-carousel',{attrs:{"interval":10000,"arrow":"never"},on:{"change":_vm.handleCarouselChange}},_vm._l((_vm.swipeList),function(item,index){return _c('el-carousel-item',{key:index,style:({
              backgroundImage: 'url(' + item.contentImg + ')',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              backgroundSize: 'cover'
            })})}),1)],1),_c('div',{staticClass:"info-new-con"},[_c('h5',{staticClass:"info-new-tit",on:{"click":function($event){return _vm.toContent(_vm.swipeList[_vm.carouselIndex])}}},[_vm._v(" "+_vm._s(_vm.swipeList[_vm.carouselIndex].contentName)+" ")]),_c('p',[_vm._v(_vm._s(_vm.swipeList[_vm.carouselIndex].extInfo))])])]):_vm._e(),_c('div',{staticClass:"infomation-content-box"},[_c('div',{staticClass:"info-con-l"},[_vm._m(0),_c('div',{staticClass:"info-new-news"},[_c('ul',{staticClass:"info-news-ul"},_vm._l((_vm.informationNewList),function(item){return _c('li',{key:item.id},[_c('span',{staticClass:"new-imgs",style:({
                  backgroundImage: 'url(' + item.contentImg + ')',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                  backgroundSize: '100% 100%'
                })}),_c('div',{staticClass:"zx-div"},[_c('h5',{staticClass:"zx-tit",on:{"click":function($event){return _vm.toContent(item)}}},[_vm._v(_vm._s(item.contentName))]),_c('p',[_vm._v(_vm._s(item.extInfo))]),_c('div',{staticClass:"zx-info-div"},[_c('div',{staticClass:"zx-info-l"},[(item.isTop === 2)?_c('el-button',{staticClass:"zx-btn btn-red"},[_vm._v("置顶")]):(item.isRecommend === 2)?_c('el-button',{staticClass:"zx-btn btn-or"},[_vm._v("热门")]):_vm._e(),_c('span',{staticClass:"zx-area"},[_vm._v(_vm._s(item.source))])],1),_c('span',{staticClass:"zx-time"},[_vm._v(_vm._s(item.createTime.split(' ')[0]))])])])])}),0)]),(_vm.pageCount > 1)?_c('div',{staticClass:"custon-page"},[_c('el-button',{staticClass:"page-btn",on:{"click":function($event){return _vm.handleCurrentChange(1)}}},[_c('span',[_vm._v("首页")])]),_c('el-pagination',{attrs:{"current-page":_vm.current,"layout":"pager,slot,jumper","pager-count":7,"page-count":_vm.pageCount,"total":_vm.total},on:{"current-change":_vm.handleCurrentChange}},[_c('el-button',{staticClass:"page-btn",on:{"click":function($event){return _vm.handleCurrentChange(_vm.pageCount)}}},[_c('span',[_vm._v("尾页")])]),_c('span',{staticClass:"totals"},[_vm._v("第"+_vm._s(_vm.current)+"页/共"+_vm._s(_vm.pageCount)+"页")])],1)],1):_vm._e()]),_c('div',{staticClass:"info-con-r"},[_vm._m(1),_c('div',{staticClass:"text-seach-box"},[_c('span',{staticClass:"txt-span"},[_vm._v("关键词")]),_c('el-autocomplete',{attrs:{"popper-class":"my-autocomplete","fetch-suggestions":_vm.querySearch,"placeholder":"请输入内容"},on:{"select":_vm.handleSelect},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.queryFileList()}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(item.value)+" ")]}}]),model:{value:(_vm.queryText),callback:function ($$v) {_vm.queryText=$$v},expression:"queryText"}}),_c('el-button',{staticClass:"txt-search",on:{"click":function($event){return _vm.queryFileList()}}},[_vm._v("搜索")])],1),_c('ul',{staticClass:"txt-list"},_vm._l((_vm.fileList),function(item){return _c('li',{key:item.index},[_vm._m(2,true),_c('p',{on:{"click":function($event){return _vm.download(item)}}},[_vm._v(_vm._s(item.originalName))])])}),0),_c('div',{staticClass:"webcat-box"},[_c('img',{attrs:{"src":_vm.webcat}}),_c('p',[_vm._v("打开微信扫扫一扫")]),_c('p',[_vm._v("关注南平非遗保护中心官方公众号了解更多资讯动态")])])])])]),_c('botto-com',{staticClass:"bottom-white no-fixed"})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h5',{staticClass:"page-tit-s page-txt"},[_c('i',{staticClass:"tit-icon2"}),_c('span',{staticClass:"tit-red"},[_vm._v("资讯")]),_vm._v("动态 ")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h5',{staticClass:"page-tit-s page-txt"},[_c('i',{staticClass:"tit-icon2"}),_c('span',{staticClass:"tit-red"},[_vm._v("文件")]),_vm._v("下载 ")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"cir-icon"},[_c('i',{staticClass:"txt-circle"})])}]

export { render, staticRenderFns }