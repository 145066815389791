<template>
  <div class="page-content-box">
    <div
      class="info-banner"
      :style="{
        backgroundImage: 'url(' + bannerImg + ')',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover'
      }"
    >
      <head-main class="no-fixed"></head-main>
    </div>

    <div class="infomation-box">
      <div class="info-new-box" v-if="swipeList.length > 0">
        <div class="info-new-banner">
          <el-carousel :interval="10000" arrow="never" @change="handleCarouselChange">
            <el-carousel-item
              v-for="(item, index) in swipeList"
              :key="index"
              :style="{
                backgroundImage: 'url(' + item.contentImg + ')',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundSize: 'cover'
              }"
            >
              <!-- <h3>{{ index }}</h3> -->
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="info-new-con">
          <h5 class="info-new-tit" @click="toContent(swipeList[carouselIndex])">
            {{ swipeList[carouselIndex].contentName }}
          </h5>
          <p>{{ swipeList[carouselIndex].extInfo }}</p>
        </div>
      </div>
      <div class="infomation-content-box">
        <div class="info-con-l">
          <h5 class="page-tit-s page-txt">
            <i class="tit-icon2"></i>
            <span class="tit-red">资讯</span>动态
          </h5>
          <div class="info-new-news">
            <ul class="info-news-ul">
              <li v-for="item in informationNewList" :key="item.id">
                <span
                  class="new-imgs"
                  :style="{
                    backgroundImage: 'url(' + item.contentImg + ')',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    backgroundSize: '100% 100%'
                  }"
                ></span>
                <div class="zx-div">
                  <h5 class="zx-tit" @click="toContent(item)">{{ item.contentName }}</h5>
                  <p>{{ item.extInfo }}</p>
                  <div class="zx-info-div">
                    <div class="zx-info-l">
                      <el-button v-if="item.isTop === 2" class="zx-btn btn-red">置顶</el-button>
                      <el-button v-else-if="item.isRecommend === 2" class="zx-btn btn-or">热门</el-button>
                      <span class="zx-area">{{ item.source }}</span>
                    </div>
                    <span class="zx-time">{{ item.createTime.split(' ')[0] }}</span>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div class="custon-page" v-if="pageCount > 1">
            <el-button class="page-btn" @click="handleCurrentChange(1)"><span>首页</span></el-button>
            <el-pagination
              :current-page="current"
              layout="pager,slot,jumper"
              :pager-count="7"
              :page-count="pageCount"
              :total="total"
              @current-change="handleCurrentChange"
            >
              <el-button class="page-btn" @click="handleCurrentChange(pageCount)"><span>尾页</span></el-button>
              <span class="totals">第{{ current }}页/共{{ pageCount }}页</span>
            </el-pagination>
          </div>
        </div>
        <div class="info-con-r">
          <h5 class="page-tit-s page-txt">
            <i class="tit-icon2"></i>
            <span class="tit-red">文件</span>下载
          </h5>
          <div class="text-seach-box">
            <span class="txt-span">关键词</span>
            <el-autocomplete
              popper-class="my-autocomplete"
              v-model="queryText"
              :fetch-suggestions="querySearch"
              placeholder="请输入内容"
              @select="handleSelect"
              @keyup.enter.native="queryFileList()"
            >
              <!-- <i class="el-icon-circle-close el-input__icon" slot="suffix" @click="handleIconClick"> </i> -->
              <template slot-scope="{ item }">
                {{ item.value }}
              </template>
            </el-autocomplete>
            <el-button class="txt-search" @click="queryFileList()">搜索</el-button>
          </div>
          <ul class="txt-list">
            <li v-for="item in fileList" :key="item.index">
              <span class="cir-icon"><i class="txt-circle"></i></span>
              <p @click="download(item)">{{ item.originalName }}</p>
            </li>
          </ul>
          <div class="webcat-box">
            <img :src="webcat" />
            <p>打开微信扫扫一扫</p>
            <p>关注南平非遗保护中心官方公众号了解更多资讯动态</p>
          </div>
        </div>
      </div>
    </div>

    <botto-com class="bottom-white no-fixed"></botto-com>
  </div>
</template>
<script>
import HeadMain from '../../components/head'
import BottoCom from '../../components/bottom'
import { saveAs } from 'file-saver'

export default {
  components: {
    HeadMain,
    BottoCom
  },
  data() {
    return {
      restaurants: [],
      queryText: '',
      bannerImg: require('../../assets/images/zx-banner.jpg'),
      webcat: require('../../assets/images/webcat.png'),
      carouselIndex: 0,
      swipeList: [],
      informationNewList: [],
      current: 1,
      total: 0,
      pageCount: 0,
      fileList: []
    }
  },
  methods: {
    querySearch(queryString, cb) {
      var restaurants = this.restaurants
      var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants
      // 调用 callback 返回建议列表的数据
      cb(results)
    },
    createFilter(queryString) {
      return restaurant => {
        return restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0
      }
    },
    getInformationNewList() {
      this.axios
        .get('/api/portal/page/template-data', {
          params: {
            columnId: this.$route.query.id,
            current: this.current
          }
        })
        .then(res => {
          if (res.data.cellData) {
            let data = res.data.cellData
            this.swipeList = data.swipe.records
            let list = data.list
            this.fileList = data.attach.records
            this.informationNewList = list.records
            this.total = list.total
            this.pageCount = list.pages
          }
        })
    },
    handleCarouselChange(index) {
      this.carouselIndex = index
    },
    handleSelect(item) {
      console.log(item)
    },
    handleIconClick(ev) {
      console.log(ev)
    },
    toContent({ contentUrl, id, contentId }) {
      this.$router.push({ path: contentUrl, query: { id, contentId } })
    },
    handleCurrentChange(current) {
      this.current = current
      this.getInformationNewList()
    },
    queryFileList() {
      this.axios
        .get('/api/portal/page/template-data', {
          params: {
            columnId: this.$route.query.id,
            current: this.current,
            contentName: this.queryText
          }
        })
        .then(res => {
          if (res.data.cellData) {
            let search = res.data.cellData.search
            this.fileList = search ? search.records : []
          }
        })
    },
    download(item) {
      saveAs(item.link, item.originalName)
    }
  },
  mounted() {
    this.restaurants = [
      // { value: '三全鲜食（北新泾店）' },
      // { value: 'Hot honey 首尔炸鸡（仙霞路）' },
      // { value: '新旺角茶餐厅' },
      // { value: '泷千家(天山西路店)' },
      // { value: '胖仙女纸杯蛋糕（上海凌空店）' },
      // { value: '贡茶' },
      // { value: '豪大大香鸡排超级奶爸' }
    ]
    this.getInformationNewList()
  }
}
</script>
<style lang="scss" scoped></style>
